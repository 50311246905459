<template>
    <b-modal id="video-viewer-modal" no-close-on-backdrop static centered hide-footer>
        <template #modal-header="{ close }">
            <div></div>
            <b-button size="sm" @click="close()"> X </b-button>
        </template>
        <div>
          <div class="player-box p-2">
            <div class="plyr__video-embed" id="player">
              <iframe width="100%" height="100%"
                :src="`https://player.vimeo.com/video/${videoID}?autoplay=1&controls=0&transparent=0&color=#007EC6`"
                allowfullscreen
                allowtransparency
                allow="autoplay"
                frameborder="0" 
                webkitallowfullscreen 
                mozallowfullscreen
              ></iframe>
              <!-- title=0&byline=0&portrait=0&sidedock=0&loop=0&speed=1&transparent=0&autoplay=1&share=0&gesture=media&color=#007EC6 -->
              <!-- &controls=0 -->
            </div>
          </div>
        </div>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "VideoViewerModal",
  computed: {
    ...mapGetters({
      videoID: 'quiz/currentVideoID'
    }),
  },
};
</script>

<style scoped>
  .player-box {
    height: 70vh;
    overflow: hidden;
  }
  .player-box .plyr {
    height: 800px !important;
  }

  iframe {
    border: 0 !important;
    border-radius: 1rem;
  }
</style>

